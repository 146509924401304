<template>
  <ClientOnly>
    <FormKit
      :id="id"
      type="form"
      class="max-w-md"
      :actions="false"
      :incomplete-message="false"
      spellcheck="false"
      :config="{ validationVisibility: 'blur' }"
      @submit="submit"
    >
      <div v-if="headline.length > 0 || info" ref="el" class="pb-[23px]">
        <Headline
          v-if="!(dialogStore.headline && isInDialog) && headline.length > 0"
          :headline="t(headline)"
          level="h1"
          :has-decoration="true"
          classes="break-keep whitespace-break-spaces"
        />
        <div class="js-error-container scroll-mt-3xl">
          <slot name="errors" />
        </div>
        <div v-if="info" class="mt-sm">
          <InfoFrame>
            {{ t(info) }}
          </InfoFrame>
        </div>
      </div>

      <slot />

      <BasicButton
        classes="w-full px-sm md:px-0 pt-sm"
        :label="t(submitLabel)"
        :type="EButtonType.SUBMIT"
        :btn-style="EButtonStyle.CTA"
        :loading-animation="isLoading"
        bg-color="var(--thm-btn-primary-base)"
        :disabled="isLoading"
        full-width
        :testid="testId ?? ''"
        @click="checkValidity"
      />
    </FormKit>
  </ClientOnly>
</template>
<script setup lang="ts">
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';
import { useDialogStore } from '@/stores/useDialogStore';
import { FormKit } from '@formkit/vue';
import { Headline, BasicButton } from '@/complib';

const { t } = useTrans();
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  headline: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  submitLabel: {
    type: String,
    default: '',
  },
  info: {
    type: String,
    default: '',
  },
  testId: {
    type: String,
    required: false,
    default: '',
  },
  scrollOffset: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits<{
  (e: 'submit', data: any): void;
}>();

function submit(data: any) {
  //needed because when the form is subitted while the input is focused, formkit throws an error when it tries to blur the already unmounted input
  (document.activeElement as HTMLElement)?.blur();
  emit('submit', data);
}

const dialogStore = useDialogStore();

const el = ref<HTMLElement | null>(null);
const isInDialog = useIsInDialog(el);

async function checkValidity() {
  const form = document.getElementById(props.id);
  if (!form) return;

  // Sleep for quarter second till form is updated with validation information
  await new Promise((r) => setTimeout(r, 250));
  const invalidElements = form.querySelectorAll('[data-invalid=true]');

  if (!invalidElements.length) return;

  const dialog = document.querySelector('.js-dialog-scroll-container');

  if (dialog) {
    dialog.scrollTo({
      top:
        invalidElements[0].getBoundingClientRect().top +
        dialog.scrollTop -
        dialog.getBoundingClientRect().top -
        props.scrollOffset,
      behavior: 'smooth',
    });
  } else
    invalidElements[0].scrollIntoView({
      behavior: 'smooth',
    });
}
</script>
